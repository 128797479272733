import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Box, Flex } from 'rebass';
import { formatDistance, differenceInDays } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { kebabCase, orderBy } from 'lodash';
import Helmet from 'react-helmet';

import Layout from '../components/Layout';
import { Badge, TagIcon, Label } from '../components/ui';
import { StaticQuery } from '../../.cache/gatsby-browser-entry';

const Panel = styled(Box)`
  background: #fff;
  overflow: auto;
  margin: 40px 0;
  border-radius: 3px;
  padding-left: 30px;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);

  @media (max-width: 766px) {
    :hover {
      box-shadow: 0 14px 14px 0 rgba(50, 50, 93, 0.1),
        0 5px 12px 0 rgba(0, 0, 0, 0.07);
      transform: translateX(30px);
    }
  }

  transition: all 200ms ease;
  display: flex;
  position: relative;
  overflow: hidden;

  @media (max-width: 1024px) {
    height: auto;
    padding-left: 0;
  }
`;

const ArticleLink = styled(Link)`
  display: block;
  text-decoration: none;

  :hover {
    text-decoration: none;
  }
`;

const ArticleImage = styled.img`
  width: 250px;
  object-fit: cover;
  margin-left: auto;
  transition: all 200ms ease;
  margin-bottom: 0;

  @media (hover: hover) {
    ${ArticleLink}:hover & {
      filter: brightness(90%);
      transform: rotate3d(1, 1, 1, -1deg);
    }
  }

  @media (max-width: 1024px) {
    width: auto;
    margin-left: 0;
  }
`;

const Tag = (props) => (
  <Link {...props} className="text-sm font-semibold text-indigo-700" />
);

const ArticlePreview = ({ title, link, date, description, tags, imageUrl }) => (
  <ArticleLink to={link}>
    <Panel>
      <Flex
        flexDirection={[
          'column-reverse',
          'column-reverse',
          'column-reverse',
          'row',
        ]}
        justifyContent="space-between"
        css={`
          width: 100%;
        `}
      >
        <Box
          pr={[3, 3, 3, 3]}
          pl={[3, 3, 3, 0]}
          pt={[3, 3, 3, 0]}
          pb={[3, 3, 3, 3]}
          flex="1 1 0%"
        >
          <Box mt={[0, 0, 0, 3]}>
            <h2 className="text-2xl font-semibold text-gray-900">{title}</h2>
            <Box mb={1} mt={1}>
              <Flex alignItems="center">
                <Label>
                  {formatDistance(new Date(date), new Date(), {
                    locale: frLocale,
                    addSuffix: true,
                  })}
                </Label>
                {differenceInDays(new Date(), new Date(date), {
                  unit: 'day',
                }) <= 7 && (
                  <Box ml={3}>
                    <Badge>Nouveau</Badge>
                  </Box>
                )}
              </Flex>
            </Box>
            {!!tags.length && (
              <Box mb={1} mt={1}>
                <Flex alignItems="center">
                  <TagIcon
                    css={`
                      flex-basis: 30px;
                    `}
                  />
                  <Flex
                    alignItems="center"
                    css={`
                      display: inline-flex;
                      flex-wrap: wrap;
                    `}
                  >
                    {tags.map((tag) => (
                      <Box ml={2} key={tag}>
                        <Tag to={`/tags/${kebabCase(tag)}`}>{tag}</Tag>
                      </Box>
                    ))}
                  </Flex>
                </Flex>
              </Box>
            )}
          </Box>
          <div className="mt-3 text-base text-gray-500">{description}</div>
        </Box>
        <ArticleImage src={imageUrl} />
      </Flex>
    </Panel>
  </ArticleLink>
);

export default function IndexPage() {
  // const { data } = this.props;

  return (
    <StaticQuery
      query={pageQuery}
      render={(data) => {
        const { edges: mdArticles } = data.allMarkdownRemark;
        const { edges: mdxArticles } = data.allMdx;
        const { title, siteUrl } = data.site.siteMetadata;
        const articles = orderBy(
          [...mdArticles, ...mdxArticles],
          ({ node: article }) => article.frontmatter.date,
          'desc'
        );

        return (
          <Layout sidebarContent={null}>
            <Helmet>
              <title>
                Nos articles sur la sécurité privée et la sécurité électronique
                - Lead Advisor
              </title>
              <link rel="canonical" href={`${siteUrl}/articles`} />
              <meta property="og:url" content={`${siteUrl}/articles`} />
              <meta name="twitter:url" content={`${siteUrl}/articles`} />{' '}
              <meta
                name="description"
                content="Découvrez nos articles sur la sécurité privée (gardiennage) et la sécurité électronique (télésurveillance, vidéosurveillance, alarmes…) afin de mieux protéger vos locaux et vos évènements."
              />
            </Helmet>
            <div className="flex justify-center">
              <div className="max-w-4xl">
                <div className="mb-12">
                  <h1 className="text-3xl font-extrabold tracking-tight text-center text-gray-900 sm:text-4xl">
                    Nos derniers articles sur la sécurité privée
                  </h1>
                </div>
                <div className="grid grid-flow-row gap-8">
                  {articles
                    .filter(
                      ({ node: article }) =>
                        !!article.frontmatter.showInArticles
                    )
                    .map(({ node: article }) => (
                      <ArticlePreview
                        key={article.id}
                        title={article.frontmatter.title}
                        date={article.frontmatter.date}
                        description={article.frontmatter.description}
                        link={`/${article.fields.slug}`}
                        imageUrl={
                          article.frontmatter.image.childImageSharp.fluid.src
                        }
                        tags={article.frontmatter.tags}
                      />
                    ))}
                </div>
              </div>
            </div>
          </Layout>
        );
      }}
    />
  );
}

const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "article" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            tags
            image {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            templateKey
            date
            showInArticles
            showToc
          }
        }
      }
    }
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "mdx-article" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            tags
            image {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            templateKey
            date
            showInArticles
            showToc
          }
        }
      }
    }
  }
`;
